import React, { useState, Suspense } from 'react';
import Layout from '@accrosoft-ltd/accropress-theme-alfred/src/components/layout';
import { Link } from 'gatsby';

import Modal from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';

import WigLoader from '@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader';
import WeducNewsFeed from '@accrosoft-ltd/accropress-websites-components/dist/esm/weducNewsfeed';
import WeducDairyDates from '@accrosoft-ltd/accropress-websites-components/dist/esm/weducDairyDates';
import buildMediaContent from '@accrosoft-ltd/accropress-websites-components/dist/esm/buildMediaContent';

import NoticesSlickSlider from '@accrosoft-ltd/accropress-theme-alfred/src/components/NoticesSlickSlider';
import BasicSlider from '@accrosoft-ltd/accropress-theme-alfred/src/components/BasicSlider';
import SchoolTour from '../components/schoolTour';

import '@accrosoft-ltd/accropress-theme-alfred/src/components/wig-bundle.scss';

import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';

require('es6-promise').polyfill();
var moment = require('moment');

const gatsbyConfig = require('@accrosoft-ltd/accropress-theme-alfred/theme-config.js');

const gatsbyConfigSiteMetaData = gatsbyConfig.siteMetadata;
const gatsbyConfigPlugins = gatsbyConfig.plugins;
const gatsbyConfigPluginsAP = gatsbyConfigPlugins.find(
  (o) => o.resolve === 'gatsby-plugin-accropress'
);

const gatsbyConfigPluginsAPOptions =
  gatsbyConfigPluginsAP &&
  gatsbyConfigPluginsAP.resolve === 'gatsby-plugin-accropress' &&
  gatsbyConfigPluginsAP.options
    ? gatsbyConfigPluginsAP.options
    : {};

export function NoticeTemplate(props) {
  return (
    <span>
      {props.data.attachment &&
      props.data.attachment[0] &&
      props.data.attachment[0].external_url ? (
        <a
          href={props.data.attachment[0].external_url}
          title={props.data.attachment[0].title}
          target="_blank"
        >
          <strong>
            {props.data.title.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
            {props.data.snippet
              .replace(/&nbsp;/g, '')
              .replace(/&pound;/g, '£') && ': '}
          </strong>
          {props.data.snippet.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
        </a>
      ) : (
        <>
          <strong>
            {props.data.title.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
            {props.data.snippet
              .replace(/&nbsp;/g, '')
              .replace(/&pound;/g, '£') && ': '}
          </strong>
          {props.data.snippet.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
        </>
      )}
    </span>
  );
}

export default function HomepagePagePage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navResultData,
    pluginOptions,
  },
  props,
}) {
  const config = pluginOptions;

  const [toggled, setToggled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [noticesMainLoaded, setNoticesMainLoaded] = useState(false);
  const [noticesEmergencyLoaded, setNoticesEmergencyLoaded] = useState(false);
  const [newsfeedLoaded, setNewsfeedLoaded] = useState(false);
  const [calendarEventsLoaded, setCalendarEventsLoaded] = useState(false);

  const updateAndShowModal = (title, description, postType, attachment) => {
    setModalData({
      title: title,
      description: description,
      postType: postType,
      attachment: attachment,
    });
    setModalOpen(true);
  };

  return (
    <Layout
      {...props}
      navResultData={navResultData}
      contentDataData={contentDataData}
      integratorData={integratorData}
      contentMetaData={contentMetaData}
      pluginOptions={pluginOptions}
    >
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
        gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
        gatsbyConfigPluginsAPOptions={gatsbyConfigPluginsAPOptions}
      />
      <Modal
        classNames={{ modal: 'AccroModal' }}
        open={modalOpen && modalData ? true : false}
        onClose={() => setModalOpen(false)}
        center
      >
        {modalData && (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: modalData.description }}
            ></div>
            {modalData.attachment && modalData.attachment.length > 0 && (
              <ul className="AccroModal_Attachments">
                {modalData.attachment.map((attachment) => {
                  return (
                    <li className="AccroModal_Attachments_Attachement">
                      <a
                        href={attachment.external_url}
                        title={attachment.title}
                        target="_blank"
                        rel="noopener noreferer"
                      >
                        {attachment.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: buildMediaContent(modalData.postType),
              }}
            ></div>
          </>
        )}
      </Modal>

      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          <div className="banner home-banner">
            {contentDataData[0] &&
              contentDataData[0].children[1] &&
              contentDataData[0].children[3].children &&
              contentDataData[0].children[3].children.length > 0 && (
                <BasicSlider
                  OverlayContainer={
                    <div className="container">
                      <div className="bannerWrapper">
                        <div className="bannerText">
                          {contentDataData[0] &&
                            contentDataData[0].children[0] &&
                            contentDataData[0].children[0].value && (
                              <h1>{contentDataData[0].children[0].value}</h1>
                            )}
                          {contentDataData[0] &&
                            contentDataData[0].children[2] &&
                            contentDataData[0].children[2].value && (
                              <h2>{contentDataData[0].children[2].value}</h2>
                            )}
                          {contentDataData[0] &&
                            contentDataData[0].children[1] &&
                            contentDataData[0].children[1].value && (
                              <p>{contentDataData[0].children[1].value}</p>
                            )}
                        </div>
                      </div>
                    </div>
                  }
                  onBeforeChangeFunction={(type, previous, next) => {
                    return null;
                  }}
                  height="calc(73vh - 50px)"
                  shouldDisplayButtons={false}
                  onBeforeChangeFunction={(type, next) => {
                    return null;
                  }}
                  onBeforeChangeFunction={(type, next) => {
                    return null;
                  }}
                  slides={contentDataData[0].children[3].children}
                />
              )}

            <div
              id="weduc_notices_home_announcements_panel_wrapper"
              className={`weducWidgetDisplay_${noticesMainLoaded}`}
            >
              {toggled && (
                <div id="weduc_notices_home_announcements_panel_wrapper__content">
                  <div
                    className="weduc_notices_home_announcements_panel_wrapper__content__openclose"
                    onClick={() => setToggled(false)}
                  >
                    <span>X</span>
                  </div>

                  <NoticesSlickSlider
                    config={config}
                    setNoticesMainLoaded={setNoticesMainLoaded}
                    noticesMainLoaded={noticesMainLoaded}
                  />
                </div>
              )}

              <div
                id="weduc_notices_home_announcements_panel_wrapper__bell"
                onClick={() => setToggled(true)}
              >
                <div className="icon">
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="50px"
                    height="30px"
                    viewBox="0 0 50 30"
                    enableBackground="new 0 0 50 30"
                  >
                    <g className="bell-icon__group">
                      <path
                        className="bell-icon__ball"
                        id="ball"
                        fillRule="evenodd"
                        strokeWidth="1.5"
                        clipRule="evenodd"
                        fill="none"
                        stroke="#currentColor"
                        strokeMiterlimit="10"
                        d="M28.7,25 c0,1.9-1.7,3.5-3.7,3.5s-3.7-1.6-3.7-3.5s1.7-3.5,3.7-3.5S28.7,23,28.7,25z"
                      />
                      <path
                        className="bell-icon__shell"
                        id="shell"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#FFFFFF"
                        stroke="#currentColor"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        d="M35.9,21.8c-1.2-0.7-4.1-3-3.4-8.7c0.1-1,0.1-2.1,0-3.1h0c-0.3-4.1-3.9-7.2-8.1-6.9c-3.7,0.3-6.6,3.2-6.9,6.9h0 c-0.1,1-0.1,2.1,0,3.1c0.6,5.7-2.2,8-3.4,8.7c-0.4,0.2-0.6,0.6-0.6,1v1.8c0,0.2,0.2,0.4,0.4,0.4h22.2c0.2,0,0.4-0.2,0.4-0.4v-1.8 C36.5,22.4,36.3,22,35.9,21.8L35.9,21.8z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="mainContentWrapper home">
            <section className="app-content">
              <div className="container">
                <div className="row homepageWrapper">
                  <div className="col-12 col-8-m">
                    <h2>{contentDataData[1].children[0].value}</h2>
                    <WigLoader
                      gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                      gatsbyConfigPluginsAPOptions={
                        gatsbyConfigPluginsAPOptions
                      }
                      contentDataData={contentDataData}
                      contentMetaData={contentMetaData}
                      contentDataArray="1"
                      contentDataChild="1"
                      AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                      AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                      integratorID={config.integratorID}
                      integratorAccessToken={config.integratorAccessToken}
                    />

                    <div className="row">
                      {contentDataData[1] &&
                        contentDataData[1].children &&
                        contentDataData[1].children[2] &&
                        contentDataData[1].children[2].children &&
                        contentDataData[1].children[2].children.map(
                          (column, i) => (
                            <div className="col-12 col-6-m" key={i}>
                              <Link
                                to={column[3].value}
                                title={column[3].value}
                                className="quickLinkTile"
                                style={{
                                  backgroundImage: `url(${
                                    column[0].value
                                      ? returnResizedImageURL(
                                          { w: 385 },
                                          column[0].value
                                        )
                                      : ''
                                  })`,
                                }}
                              >
                                <div className="wrap">
                                  <div className="quickLinkText">
                                    <span>{column[1].value}</span>
                                    <span
                                      className={'fas ' + column[2].value}
                                    ></span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        )}
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <SchoolTour
                          schoolTourVideoURL="https://www.thinglink.com/card/1386737026019098627"
                          schoolTourVideoImageURL="https://cdn2.accropress.com/content-uploads/abf184ea-2e10-4a59-8d2b-d9231e226a1c/global/cT33MQfQHSsaUJXnCdeP_site_tour_image.png"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-4-m">
                    <div
                      className={
                        'sidebarMenuItem weducWidgetDisplay_' +
                        calendarEventsLoaded
                      }
                    >
                      <div className="header">
                        <h3>Upcoming Events</h3>
                        {/* <Link to="/news-and-events/events">View all</Link> */}
                      </div>
                      <WeducDairyDates
                        weducAPIURL="https://weduc.accropress.com/smartfeed/"
                        limit={5}
                        gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                        gatsbyConfigPluginsAPOptions={
                          gatsbyConfigPluginsAPOptions
                        }
                        loadedEvent={setCalendarEventsLoaded}
                        name={config.weducWebsitesID}
                        container={config.weducContainerID}
                        html={function CalendarTemplate(props) {
                          return (
                            <div
                              onClick={() =>
                                updateAndShowModal(
                                  props.data.title,
                                  props.data.description
                                )
                              }
                              className="sidebarDataItem"
                            >
                              <span className="far fa-calendar"></span>
                              <div>
                                {props.data.title}
                                <span>
                                  {moment(props.data.start_date).format('LL')}
                                </span>
                              </div>
                            </div>
                          );
                        }}
                        route={'/news-and-events/events'}
                      />
                    </div>
                    {/* <div className="sidebarMenuItem">
                      <div className="header">
                        <h3>Value of the month</h3>
                      </div>
                      <Link className="sidebarValue" to="/">
                        <span className="valueOfTheMonth_title title">
                          {contentDataData[2].children[1].title}
                        </span>
                        <p className="valueOfTheMonth_content content">
                          {contentDataData[2].children[1].content}
                        </p>
                        {contentDataData[2].children[1].author &&
                          contentDataData[2].children[1].author !== '' && (
                            <span className="valueOfTheMonth_author author">
                              {contentDataData[2].children[1].author}
                            </span>
                          )}
                      </Link>
                    </div> */}
                    <div
                      className={
                        'sidebarMenuItem weducWidgetDisplay_' + newsfeedLoaded
                      }
                    >
                      <div className="header">
                        <h3>Latest News</h3>
                        {/* <Link to="/news-and-events/latest-news">View all</Link> */}
                      </div>
                      <WeducNewsFeed
                        loadedEvent={setNewsfeedLoaded}
                        limit={5}
                        gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                        gatsbyConfigPluginsAPOptions={
                          gatsbyConfigPluginsAPOptions
                        }
                        name={config.weducWebsitesID}
                        container={config.weducContainerID}
                        html={function NewsFeedTemplate(props) {
                          return (
                            <div
                              onClick={() =>
                                updateAndShowModal(
                                  props.data.title,
                                  props.data.contentHtml,
                                  props.data.postType
                                )
                              }
                              className="sidebarDataItem"
                            >
                              <span className="far fa-newspaper"></span>
                              <div>
                                {props.data.contentHtml
                                  .replace(/<\/p>]*>?/gm, ' ')
                                  .replace(/<[^>]*>?/gm, '')
                                  .substring(0, 30)}
                                {props.data.contentHtml
                                  .replace(/<\/p>]*>?/gm, ' ')
                                  .replace(/<[^>]*>?/gm, '').length > 30
                                  ? '...'
                                  : ''}
                                <span>{props.data.createdAt}</span>
                              </div>
                            </div>
                          );
                        }}
                        route={'/news-and-events/latest-news'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
}
