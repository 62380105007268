import React, { useState } from "react"

const SchoolTour = (props) => {
  const [showTourVideo, setShowTourVideo] = useState(false)

  return (
    <div className="school-tour-wrapper">
      {showTourVideo ? (
        <iframe
          className="school-tour-video-embed"
          width="100%"
          height="630"
          src={props.schoolTourVideoURL}
          type="text/html"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          scrolling="no"
        ></iframe>
      ) : (
          <div className="school-tour-video-trigger">
            <div className="school-tour-video-trigger__image">
              <img src={props.schoolTourVideoImageURL} alt="School Tour Video" />
            </div>
            <div className="school-tour-video-trigger__overlay"
              onClick={() => setShowTourVideo(true)}>
              <div className="wrap">
                <div className="quickLinkText">
                  <div
                    onClick={() => setShowTourVideo(true)}
                  >
                    <span>Play the school tour</span>
                  </div>
                  <span
                    className={"fas fa-play-circle"}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  )
}

export default SchoolTour
